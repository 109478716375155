import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will have our FREE class today at 10:00am.  This will be our
only class today, all other classes and open gym are cancelled so come
out and join in the fun!`}</em></strong></p>
    <p><em parentName="p">{`4TH of July Partner/Team WOD`}</em></p>
    <p>{`1776M Row (as a team)`}</p>
    <p>{`76-Burpees Over Partner (while partner holds an elbow plank)`}</p>
    <p>{`76-Goblet Squat (53/35`}{`#`}{`, partner performs a parallel squat hold)`}</p>
    <p>{`76-Pullups (partner holds at the top of a ring dip)`}</p>
    <p>{`76-Situps (partner holds a “dying cockroach”)`}</p>
    <p>{`76-Pushups (partner holds a side plank)`}</p>
    <p>{`76-Wall Balls (20/14`}{`#`}{`, partner hollow rocks)`}</p>
    <p>{`760ft Sled pull (90/45`}{`#`}{`, as a team)`}</p>
    <p>{`Run 1/2 block to front doors.`}</p>
    <p>{`For time!`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Sunday (make up day) we will meet at Perrin Park (414 Perrin Lane,
Jeffersonville, IN 47130), weather permitting, for a fun outdoor wod on
the fitness loop at our normal class time of 12:30.  The park is 5 miles
from The Ville, just over 2nd street bridge about 4 miles down Utica
Pike.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      